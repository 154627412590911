/* ************************************************************
:: Template Name: Prolend - Product Landing Page Template
:: Template URI: https://theme-land.com/prolend
:: Template Author Name: theme_land
:: Template Author URI: hridoy1272@gmail.com
:: Version: 1.0.0
:: Created: 13 March 2020
************************************************************ 
*
*
******* :: INDEX OF RESPONSIVE CSS :: *******
:: 1.0 COMMON CSS
:: 2.0 HEADER AREA CSS
:: 3.0 WELCOME AREA CSS
:: 4.0 FAQ AREA CSS
:: 5.0 CONTACT AREA CSS
:: 6.0 FOOTER AREA CSS
****************************** */

/* ******************************
:: 1.0 COMMON CSS
****************************** */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ptb_180 {
        padding: 130px 0;
    }

    .ptb_150 {
        padding: 100px 0;
    }

    .ptb_100 {
        padding: 70px 0;
    }
}

@media (max-width: 991px) {
    .store-buttons img {
        max-width: 180px;
    }

    .store-buttons.store-black img {
        max-width: 160px;
    }

    .res-margin {
        margin-bottom: 45px;
    }
}

@media (max-width: 767px) {
    .ptb_180 {
        padding: 130px 0;
    }

    .ptb_150,
    .ptb_100 {
        padding: 50px 0;
    }

    .res-margin {
        margin-bottom: 30px;
    }
}

@media (max-width: 575px) {
    .store-buttons img {
        max-width: 160px;
    }

    .store-buttons.store-black img {
        max-width: 140px;
    }

    .avatar-lg {
        height: 4rem;
        width: 4rem;
    }

    .ptb_180 {
        padding: 80px 0;
    }
}

/*SECTION HEADING*/
@media (max-width: 991px) {
    h1 {
        font-size: 3em;
    }

    h2 {
        font-size: 36px;
    }

    .section-heading {
        margin-bottom: 50px;
    }
}

@media (max-width: 767px) {
    h1 {
        font-size: 2.8em;
    }

    h2 {
        font-size: 30px;
        line-height: 1.4;
    }
}

@media (max-width: 575px) {
    h1 {
        font-size: 2em;
        line-height: 1.4;
    }

    h2 {
        font-size: 26px;
        line-height: 1.4;
    }

    h3, .h3 {
        font-size: 18px;
        line-height: 1.4;
    }

    p {
        line-height: 26px;
    }

    .section-heading > h2 {
        line-height: 1.5;
    }
}

/*BUTTONS*/
@media (max-width: 575px) {
    .button-group a {
        margin-top: 0;
        margin-right: 5px;
    }

    .button-group .btn {
        padding: 12px 20px
    }
}




/* ******************************
:: 2.0 HEADER AREA CSS
****************************** */
@media (min-width: 992px) {
    .navbar .navbar-nav .nav-link {
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
    }

    .navbar-sticky-on .navbar-nav .nav-link {
        padding-top: 1.625rem;
        padding-bottom: 1.625rem;
    }
    .dropdown-menu {
        opacity: 0;
        display: block;
        visibility: hidden;
        min-width: 14.375rem;
        margin-top: 0;
        transform: translateY(20px);
      }
      .navbar-nav li:hover > ul.dropdown-menu {
        opacity: 1;
        transform: translateY(-10px);
        visibility: visible;
      }
}

@media (max-width: 991px) {
    .welcome-intro{
        text-align: center;
    }
    .main:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        background: #fff;
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: .3s;
        transition: .3s;
    }

    [data-theme=dark] .main:before {
        background: #01081f;
    }

    .canvas-open {
        overflow: hidden;
    }

    .canvas-open .main:before {
        width: 100%;
        opacity: .95;
        visibility: visible;
    }

    .navbar {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
    }

    .navbar.active {
        display: block;
    }

    .navbar.active .navbar-inner {
        right: 0;
    }

    .navbar .navbar-inner {
        right: -100%;
        -webkit-transition: right 0.3s 0.2s;
        transition: right 0.3s 0.2s;
    }

    .navbar .nav-link {
        border-bottom: 1px solid #eee;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    [data-theme=dark] .navbar .nav-link {
        border-bottom: 1px solid #1E2745;
    }

    .navbar.navbar-dark .nav-link {
        color: #54565a !important;
    }

    [data-theme=dark] .navbar.navbar-dark .nav-link {
        color: #f6f8f9;
    }

    .navbar.navbar-dark .nav-link:hover,
    .navbar.navbar-dark .nav-link:focus,
    .navbar.navbar-dark .nav-link.active,
    .navbar.navbar-dark .nav-link.current-menu-item {
        color: #2c2e30;
    }

    [data-theme=dark] .navbar.navbar-dark .nav-link {
        color: #e7eaee;
    }

    [data-theme=dark] .navbar.navbar-dark .nav-link:hover {
        background: #01081f;
    }
    [data-theme=dark] .dropdown-item:focus,
    [data-theme=dark] .dropdown-item:hover {
    background: #01081f;
  }

    .navbar-inner {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 20rem;
        padding-top: 2.5rem;
        background-color: #fff;
        -webkit-box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.15);
        box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.15);
        border-radius: 0;
        overflow-y: auto;
        z-index: 1050;
    }

    [data-theme=dark] .navbar-inner {
        background: #151c32;
    }

    .navbar-inner .dropdown-menu:before {
        content: none;
    }

    .navbar-inner .dropdown-menu {
        border: medium none;
        background: none;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        float: none;
        margin: 0;
        padding-top: 0;
        position: static;
    }

    [data-theme=dark] .navbar-inner .dropdown-menu .dropdown-item {
        color: #f6f8f9;
    }

    [data-theme=dark] .navbar-inner .dropdown-menu .dropdown-item:hover {
        background: #01081f;
    }

    .navbar.navbar-dark .dropdown.show > .nav-link {
        color: #2c2e30;
      }
      [data-theme=dark] .navbar.navbar-dark .dropdown.show > .nav-link {
        color: #e3e4e6;
      }

    .nav-open .main-wrapper {
        -webkit-transform: translate3d(-150px, 0, 0);
        transform: translate3d(-150px, 0, 0);
    }

    /*MOBILE NAVBAR TOGGLER*/
    .navbar-toggler {
        position: absolute !important;
        top: auto;
        right: 0;
        bottom: auto;
        left: auto;
        border: none;
        height: 100%;
        width: 3.75rem;
        background-image: none;
        border-radius: 0;
        z-index: 1;
        display: inline-block;
    }

    .navbar-inner .navbar-toggler {
        position: absolute;
        height: 2.5rem;
        top: 0;
    }

    .navbar-toggler-icon {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background-image: none !important;
    }

    .navbar-toggler-icon,
    .navbar-toggler-icon:before,
    .navbar-toggler-icon:after {
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }

    .navbar-toggler-icon {
        height: 3px !important;
        width: 50%;
        background: #7e8085;
        display: inline-block;
    }

    .navbar-dark .navbar-toggler-icon {
        background: #fff;
    }

    .navbar-sticky-on .navbar-toggler-icon {
        background: #fff;
    }

    .navbar-dark .navbar-toggler-icon.active:before,
    .navbar-dark .navbar-toggler-icon.active:after {
        background: #7e8085;
    }

    .navbar-toggler-icon:before,
    .navbar-toggler-icon:after {
        content: "";
        background: #7e8085;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .navbar-dark .navbar-toggler-icon:before,
    .navbar-dark .navbar-toggler-icon:after {
        background: #fff;
    }

    .navbar-sticky-on .navbar-toggler-icon:before,
    .navbar-sticky-on .navbar-toggler-icon:after {
        background: #fff;
    }

    .navbar-toggler-icon::before {
        top: -0.625rem;
    }

    .navbar-toggler-icon::after {
        top: 0.625rem;
    }

    .navbar-toggler-icon.active {
        background-color: transparent;
        -webkit-transform: scale(0.85) rotate(270deg);
        transform: scale(0.85) rotate(270deg);
    }

    .navbar-toggler-icon.active:before {
        top: 0;
        -webkit-transform: scale(0.65) rotate(45deg);
        transform: scale(0.65) rotate(45deg);
    }

    .navbar-toggler-icon.active:after {
        top: 0;
        -webkit-transform: scale(0.65) rotate(-45deg);
        transform: scale(0.65) rotate(-45deg);
    }
}

/* ******************************
:: 3.0 WELCOME AREA CSS
****************************** */
@media (max-width: 991px) {
     .navbar-brand-regular,.navbar-brand-sticky{
        width: 70%;
    }
    .welcome-area,
    .inner .welcome-area {
        height: 700px !important;
    }

    .homepage-3 .welcome-area .subscribe-form {
        width: 100%;
    }

    .homepage-5 .welcome-area {
        height: 1150px !important;
    }
    .other-page-area .selectLanguage{
        margin-top: 19px;
    align-items: center;
    text-align: center;
    }
}

@media (max-width: 767px) {
     .navbar-brand-regular,.navbar-brand-sticky{
        width: 60%;
    }
    .welcome-area,
    .homepage-5 .welcome-area {
        height: 100% !important;
        padding-top: 90px;
    }

    .welcome-intro {
        margin-top: 0;
        text-align: center;
    }
    .button-group {
        margin-top: 30px;
        text-align: center;
        display: block !important;
    }

    .welcome-thumb {
    padding-top: 20px;
    position: relative;
    /*height: 700px;*/
}

    .homepage-4 .welcome-thumb {
        max-width: 350px;
    }
}

@media (max-width: 575px) {
     .navbar-brand-regular,.navbar-brand-sticky{
        width: 50%;
    }
    .welcome-intro {
        margin-top: 0;
    }

    .homepage-3 .welcome-area .subscribe-form input {
        text-indent: 0;
    }

}

/* ******************************
:: 4.0 FAQ AREA CSS
****************************** */
@media (max-width: 575px) {
     .welcome-thumb {
    /*height: 650px;*/
}
    .card-header .btn {
        line-height: 1.4;
    }
    .heading-main,.heading-sub {
        font-size: 2rem;
    }
    .welcome-intro p{
        font-size:15px;
        text-align:justify;
        text-align-last:center;
    }
}

/* ******************************
:: 5.0 CONTACT AREA CSS
****************************** */
@media (max-width: 575px) {
    .homepage-5 .contact-top > h3 {
        font-size: 1.8em;
    }
    .homepage-5 .contact-top h5 {
        font-size: 14px;
    }
}

/* ******************************
:: 6.0 FOOTER AREA CSS
****************************** */
@media (min-width: 992px) {
    .footer-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
    .footer-items {
        margin-bottom: 30px;
    }
}

@media (max-width: 575px) {
    .footer-items {
        margin-bottom: 30px;
    }

    .footer-area .col-12:last-of-type .footer-items {
        margin-bottom: 0;
    }
}

@media (max-width: 480px) {
   .welcome-thumb {
    /*height: 675px;*/
}
}

@media (max-width: 370px) {
   .welcome-thumb {
    /*height: 455px;*/
}
}
